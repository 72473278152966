import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./dwRanking.css";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import LoaderSpin from "../../../components/common/LoaderSpin";
import ReactTooltip from "react-tooltip";
import { languageText } from "../../../languageText/dwRanking"
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import DwTermsSideBars from "../../../components/common/Sidebar/DwTermsSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"
import Slider from "react-slick";
const DwRanking = () => {
  
  const api = new API();
  const [ranking, setranking] = useState([]);
  const [dranking, setDwranking] = useState({});
  const [render, setRander] = useState("");
  const [loader, setLoader] = useState(true);
  const [uRic, setURic] = useState("");
  const [classNam, setClassNam] = useState("hide");
  const [siteLang , setSiteLang] = useState("")
  const [disclaimerDefination, setDisclaimerDefination] = useState("");
  const [dailyHsiBanner, setDailyHsiBanner] = useState()
  const [dailyHighlightBanner, setDailyHighlightBanner] = useState()
  const [dailySingleBanner, setDailySingleBanner] = useState()
  const [hsidata, setHsidata] = useState();
  const [htidata, setHtidata] = useState();
  const [hhidata, setHhidata] = useState();
  const [updateDate, setUpdateDate] = useState("");
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    dwranking();
    getNewsBanner();
    getHomeLiveIndexHSI();
    getHomeLiveIndexHTI();
    getHomeLiveIndexHHI();
  }, []);

  useEffect(() => {
    handlechange();
  }, [uRic]);

  useEffect(() => {
    getRicUrl();
  }, [ranking]);

  useEffect(() => {
    checkTickerVal();
  }, [render]);

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused ? "#007fff" : null,
        color: "#333",
        textAlign: "center",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: "21px",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
        fontFamily: "'DBHeaventLight','DBHeavent'",
        lineHeight: "1",
      };
    },
  };

  const dwranking = async () => {
    var dwres = [];
    let dranking = await api.get(config.dwRanking, true, {
      mode: 2,
      qid: Date.now(),
    });
    dranking.list.map((item) => {
      dwres.push({
        data: item.underlying_ric,
        value: item.underlying_ticker,
        label:
          `${item.underlying_ticker}` === "9988"
            ? "ALIBABA (9988)"
            : `${item.underlying_ticker}` === "0700"
            ? "TENCENT (0700)"
            : `${item.underlying_ticker}` === "700"
            ? "TENCENT (0700)"
            : `${item.underlying_ticker}` === "3690"
            ? "MEITUAN (3690)"
            : `${item.underlying_ticker}` === "1810"
            ? "XIAOMI (1810)"
            : `${item.underlying_ticker}` === "2333"
            ? "GREAT WALL MOTOR (2333)"
            : `${item.underlying_ticker}` === "9618"
            ? "JD.COM (9618)"
            : `${item.underlying_ticker}` === "0175"
            ? "GEELY (0175)"
            : `${item.underlying_ticker}` === "175"
            ? "GEELY (0175)"
            : `${item.underlying_ticker}` === "1024"
            ? "KUAISHOU TECHNOLOGY (1024)"
            : `${item.underlying_ticker}` === "1211"
            ? "BYD (1211)"
            : `${item.underlying_ticker}` === "2318"
            ? "PING AN (2318)"
            : `${item.underlying_ticker}` === "2382"
            ? "SUNNY OPTICAL TECH (2382)"
            : `${item.underlying_ticker}` === "9626"
            ? "BILIBILI (9626)"
            : `${item.underlying_ticker}` === "9888"
            ? "BAIDU (9888)"
            : `${item.underlying_ticker}` === "6690"
            ? "HAIER SMART HOME (6690)"
            : `${item.underlying_ticker}` === "1398"
            ? "ICBC (1398)"
            : `${item.underlying_ticker}` === "0005"
            ? "HSBC (0005)"
            : `${item.underlying_ticker}` === "005"
            ? "HSBC (0005)"
            : `${item.underlying_ticker}` === "05"
            ? "HSBC (0005)"
            : `${item.underlying_ticker}` === "5"
            ? "HSBC (0005)"
            : `${item.underlying_ticker}` === "1299"
            ? "AIA (1299)"
            : `${item.underlying_ticker}` === "1919"
            ? "COSCO SHIPPING (1919)"
            : `${item.underlying_ticker}` === "2269"
            ? "WUXI BIOLOGICS (2269)"
            : `${item.underlying_ticker}` === "0941"
            ? "CHINA MOBILE (0941)"
            : `${item.underlying_ticker}` === "941"
            ? "CHINA MOBILE (0941)"
            : `${item.underlying_ticker}` === "0388"
            ? "HKEX (0388)"
            : `${item.underlying_ticker}` === "388"
            ? "HKEX (0388)"
            : `${item.underlying_ticker}` === "1772"
            ? "GANFEN LITHIUM (1772)"
            : `${item.underlying_ticker}` === "2899"
            ? "ZIJIN (2899)"
            : `${item.underlying_ticker}` === "6862"
            ? "HAIDILAO (6862)"
            : `${item.underlying_ticker}` === "293"
            ? "CATHAY (293)"
            : `${item.underlying_ticker}` === "0883"
            ? "CNOOC (0883)"
            : `${item.underlying_ticker}` === "883"
            ? "CNOOC (0883)"
            : `${item.underlying_ticker}` === "0992"
            ? "LENOVO (0992)"
            : `${item.underlying_ticker}` === "992"
            ? "LENOVO (0992)"
            : `${item.underlying_ticker}` === "241"
            ? "ALIHEALTH (241)"
            : item.underlying_ticker,
      });
    });
    let array2 = dwres.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    setranking(array2);
    var urlVal = window.location.pathname.split("/")
    if(urlVal.length == 4){
      let finalVal
      array2.filter((e) => {
        if(e.value == urlVal[3]){
          finalVal = e
        }
      })
      setRander(finalVal?.value)
      setURic(finalVal?.data)
    }else{
      setRander(array2[0].value)
      setURic(array2[0].data)
    }
  };

  const selectChange = (e) => {
    setURic(e.data);
    setRander(e.value);
    window.history.pushState("", "", `/tools/dwranking/${e.value}`);
  };

  const handlechange = (e) => {
    setLoader(true);
    const getdata = async () => {
      const request = await api.get(config.dwRanking, true, {
        mode: 3,
        uric: uRic,
        qid: Date.now(),
      });
      setLoader(false);
      setDwranking(request);

      return request;
    };
    getdata();
  };

  const getRicUrl = () => {
    let queryString = window.location.pathname.split("/");
    let splitData = queryString[4];
    if (splitData != undefined && ranking.length > 0) {
      setRander(splitData);
      let ricData = ranking.filter((rk) => splitData == rk.value);    
      let finalRicVal = ricData[0].data;      
      setURic(finalRicVal);
    }
  };

  const checkTickerVal = () => {
    if (
      render == "SET50" ||
      render == "HSI" ||
      render == "HSTECH" ||
      render == "S&P500" ||
      render == "NDX" ||
      render == "DJI" ||
      render == "HSCEI"
    ) {
      setClassNam("");
    } else {
      setClassNam("hide");
    }
  };

  const disclaimerDefinationFun = () => {
    if(disclaimerDefination == ""){
      setDisclaimerDefination("in")
    }else{
      setDisclaimerDefination("")
    }
  }
  const getNewsBanner = async () => {
    let bannerResult = await api.get(config.homeNewsBanner, true);
    var dailyHsi = bannerResult.daily_hsi
    var dailyHighlight = bannerResult.daily_highlight
    var dailySingleStock = bannerResult.daily_single_stock
    if (dailyHsi?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        hsi: dailyHsi[0].id,
      });
      setDailyHsiBanner({
        img: advImgData,
        id: dailyHsi[0].id,
      });
    }
    if (dailyHighlight?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        dailyid: dailyHighlight[0].id,
      });
      setDailyHighlightBanner({
        img: advImgData,
        id: dailyHighlight[0].id,
      });
    }
    if (dailySingleStock?.length > 0) {
      let advImgData = await api.get(config.advImgData, true, {
        singlestock: dailySingleStock[0].id,
      });
      setDailySingleBanner({
        img: advImgData,
        id: dailySingleStock[0].id,
      });
    }
  };
  const getHomeLiveIndexHSI = async () => {
    let LiveIndexHSIResult = await api.get(config.livenewsdata1, true, {
      ric: "HSI",
    });
    setHsidata(LiveIndexHSIResult);
    let date = new Date(LiveIndexHSIResult.stime);
    let hours = date.getHours();
    let min = date.getMinutes();
    let today = new Date();
    let s = today.getSeconds();
    if (s < 10) {
      s = "0" + s;
    }
    hours = hours - 1;
    var ampm = "AM";
    if (hours > 11) {
      if (hours != 12) hours = hours - 12;
      ampm = "PM";
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (min < 10) {
      min = "0" + min;
    }
    setUpdateDate(hours + ":" + min + ":" + s + " " + ampm);
  };

  const getHomeLiveIndexHTI = async () => {
    let LiveIndexHTIResult = await api.get(config.livenewsdata1, true, {
      ric: "HTI",
    });
    setHtidata(LiveIndexHTIResult);
  };

  const getHomeLiveIndexHHI = async () => {
    let LiveIndexHHIResult = await api.get(config.livenewsdata1, true, {
      ric: "HHI",
    });
    setHhidata(LiveIndexHHIResult);
  };

  const refreshdata = () => {
    getHomeLiveIndexHSI()
    getHomeLiveIndexHTI()
    getHomeLiveIndexHHI()
  }
  return (
    <>
      <div id="dwranking" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">
                  {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active"> {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid" >
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p className="section-p">
                  {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                </p>
                <Select
                  className="select_align_center"
                  placeholder={"Select underlying"}
                  onChange={selectChange}
                  options={ranking}
                  styles={colourStyles}
                />
                <div className="uric">
                  Underlying: {render}
                  <span id="utickerLabel" className="uric-lbl"></span>
                </div>
                <div id="dwtblgroup" style={{ position: "relative" }}>
                  <table id="calltable" className="rktable table table-striped top-table" >
                    <thead>
                      <tr>
                        <th className="th-nm" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.callTableCallDwEn : languageText.callTableCallDw}} />
                        <th className="th-id" />
                        <th className="th-bd" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.callTableBidEn : languageText.callTableBid}} />
                        <th className="th-eg">
                          <span 
                            className="ttip" 
                            data-tip 
                            data-for={"effectivegearingTooltip"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.callTableGearingEn : languageText.callTableGearing}}
                           />
                          <ReactTooltip
                            id={"effectivegearingTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.effectiveGearingTooltipEn : languageText.effectiveGearingTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className="th-ss">
                          <span
                            className="ttip"
                            data-for={"sensitivityTooltip"}
                            data-tip
                          >
                            {siteLang == "eng" ? languageText.callTableSensitivityEn : languageText.callTableSensitivity}
                          </span>
                          <ReactTooltip
                            id={"sensitivityTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.sensitivityTooltipEn : languageText.sensitivityTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className={`th-si ${classNam}`}>
                          <span
                            className="ttip"
                            data-for={"sensitivityIndexTooltip"}
                            data-tip
                          >
                            {siteLang == "eng" ? languageText.callTableSensitivityPointEn : languageText.callTableSensitivityPoint}
                          </span>
                          <ReactTooltip
                            id={"sensitivityIndexTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.sensitivityIndexTooltipEn : languageText.sensitivityIndexTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                      </tr>
                    </thead>
                    {loader && (
                      <tbody>
                        <tr style={{ backgroundColor: "#fff" }}>
                          <td colSpan={26}>
                            <div>
                              <LoaderSpin isVisible />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                    <tbody>
                      {dranking && dranking.calllist && dranking.calllist.length > 0 ? (
                        Object.values(dranking.calllist).map((list1, index) => {
                          return (
                            <>
                              <tr className="rowtmpl" key={index}>
                                <td className="col_tkr" style={{ fontWeight: "bold" }}>
                                  <Link to={`/tools/livematrix/${list1?.ticker}`}>
                                    {list1 && list1.ticker}
                                  </Link>
                                </td>
                                <td className="col_id">
                                  {list1 && list1.FTCHK === true && (
                                    <img src="/img/bullet/fasttrack.png" />
                                  )}
                                  {list1 && list1.LOWTD === true && (
                                    <img src="/img/bullet/time.png" />
                                  )}
                                  {list1 && list1.NEAREXP === true && (
                                    <img src="/img/bullet/expiry.fw.png" />
                                  )}
                                  {list1 && list1.Responsive === true && (
                                    <img src="/img/bullet/responsive.png" />
                                  )}
                                  {list1 && list1.SOLDOUT === true && (
                                    <img src="/img/bullet/soldout.fw.png" />
                                  )}
                                  {list1 && list1.CAUTION === true && (
                                    <img src="/img/bullet/caution.png" />
                                  )}
                                </td>
                                {list1.BID === null ? (
                                  <td className="col_bd">-</td>
                                ) : (
                                  <td className="col_bd">{list1.BID}</td>
                                )}
                                {list1.effective_gearing === null ? (
                                  <td className="col_eg">-</td>
                                ) : (
                                  <td className="col_eg">
                                    {list1.effective_gearing}
                                  </td>
                                )}
                                {list1.sensitivity === null ? (
                                  <td className="col_sstvt">-</td>
                                ) : (
                                  <td className="col_sstvt">
                                    {list1.sensitivity}
                                  </td>
                                )}
                                <td className={`col_sstvti ${classNam}`}>
                                  {(list1.sensitivity === 0 || list1.sensitivity === null || list1.sensitivity === "0.00" || list1.sensitivity === "0.0" || list1.sensitivity === "0")
                                    ? "-" : (1.0 / Number(list1.sensitivity_cal)).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr className="emptymsg">
                          <td colSpan="5">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <table id="puttable" className="table table-striped">
                    <thead>
                      <tr>
                        <th className="th-nm" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.putTableCallDwEn : languageText.putTableCallDw}} />
                        <th className="th-id" />
                        <th className="th-bd" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.callTableBidEn : languageText.callTableBid}} />
                        <th className="th-eg">
                          <span 
                            className="ttip" 
                            data-tip 
                            data-for={"effectivegearingTooltip"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.callTableGearingEn : languageText.callTableGearing}}
                           />
                          <ReactTooltip
                            id={"effectivegearingTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.effectiveGearingTooltipEn : languageText.effectiveGearingTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className="th-ss">
                          <span
                            className="ttip"
                            data-for={"sensitivityTooltip"}
                            data-tip
                          >
                            {siteLang == "eng" ? languageText.callTableSensitivityEn : languageText.callTableSensitivity}
                          </span>
                          <ReactTooltip
                            id={"sensitivityTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.sensitivityTooltipEn : languageText.sensitivityTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className={`th-si ${classNam}`}>
                          <span
                            className="ttip"
                            data-for={"sensitivityIndexTooltip"}
                            data-tip
                          >
                            {siteLang == "eng" ? languageText.callTableSensitivityPointEn : languageText.callTableSensitivityPoint}
                          </span>
                          <ReactTooltip
                            id={"sensitivityIndexTooltip"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.sensitivityIndexTooltipEn : languageText.sensitivityIndexTooltip}} 
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                      </tr>
                    </thead>
                    {loader && (
                      <tbody>
                        <tr style={{ backgroundColor: "#fff" }}>
                          <td colSpan={26}>
                            <div>
                              <LoaderSpin isVisible />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                    <tbody>
                      {dranking && dranking.putlist && dranking.putlist.length > 0 ? (
                        Object.values(dranking.putlist).map((list1, index) => {
                          return (
                            <>
                              <tr className="rowtmpl" key={index}>
                                <td className="col_tkr" style={{ fontWeight: "bold" }}>
                                  <Link to={`/tools/livematrix/${list1.ticker}`} >
                                    {list1 && list1.ticker}
                                  </Link>
                                </td>
                                <td className="col_id">
                                  {list1 && list1.FTCHK === true && (
                                    <img src="/img/bullet/fasttrack.png" />
                                  )}
                                  {list1 && list1.LOWTD === true && (
                                    <img src="/img/bullet/time.png" />
                                  )}
                                  {list1 && list1.NEAREXP === true && (
                                    <img src="/img/bullet/expiry.fw.png" />
                                  )}
                                  {list1 && list1.Responsive === true && (
                                    <img src="/img/bullet/responsive.png" />
                                  )}
                                  {list1 && list1.SOLDOUT === true && (
                                    <img src="/img/bullet/soldout.fw.png" />
                                  )}
                                  {list1 && list1.CAUTION === true && (
                                    <img src="/img/bullet/caution.png" />
                                  )}
                                </td>
                                {list1.BID === null ? (
                                  <td className="col_bd">-</td>
                                ) : (
                                  <td className="col_bd">{list1.BID}</td>
                                )}
                                {list1.effective_gearing === null ? (
                                  <td className="col_eg">-</td>
                                ) : (
                                  <td className="col_eg">
                                    {list1.effective_gearing}
                                  </td>
                                )}
                                {list1.sensitivity === null ? (
                                  <td className="col_sstvt">-</td>
                                ) : (
                                  <td className="col_sstvt">
                                    {list1.sensitivity}
                                  </td>
                                )}
                                <td className={`col_sstvti ${classNam}`}>
                                  {(list1.sensitivity === 0 || list1.sensitivity === null || list1.sensitivity === "0.00" || list1.sensitivity === "0.0" || list1.sensitivity === "0")
                                    ? "-"
                                    : (1.0 / Number(list1.sensitivity_cal)).toFixed(2)}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr className="emptymsg">
                          <td colSpan="5">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="hidden-sm hidden-xs">
                  <ul className="description">
                    <li className="description-hotdw" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionHotDwEn : languageText.descriptionHotDw}} />
                    <li className="description-time" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionTimeEn : languageText.descriptionTime}} />
                    <li className="description-caution" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionCautionEn : languageText.descriptionCaution}} />
                    <li className="description-expiry" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionExpiryEn : languageText.descriptionExpiry}} />
                    <li className="description-soldout" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionSoldOutEn : languageText.descriptionSoldOut}} />
                    <li className="description-responsive" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionResponsiveEn : languageText.descriptionResponsive}} />
                  </ul>
                  <hr />
                  <p className="small">
                    {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                  </p>
                </div>
                <div
                  className="panel-group visible-xs-block visible-sm-block"
                  id="defination"
                >
                  <div className="panel panel-default">
                    <div className="panel-heading" onClick={disclaimerDefinationFun}>
                      <button>
                        <span className="pull-right">
                          <img src="/img/bg/questionMark.png" alt="questionMark" />
                        </span>
                        <div id="collapse_title3" className="panel-title text-center color-primary">
                          Definition &amp; disclaimer
                        </div>
                      </button>
                    </div>
                    <div id="collapse_dwcompare3" className={`panel-collapse collapse ${disclaimerDefination}`} >
                      <p className="small">
                        {siteLang == "eng" ? languageText.definationSensitivityEn : languageText.definationSensitivity}
                      </p>
                      <p className="small">
                        {siteLang == "eng" ? languageText.definationEffectiveGearingEn : languageText.definationEffectiveGearing}
                      </p>
                      <ul className="description">
                        <li className="description-hotdw" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionHotDwEn : languageText.descriptionHotDw}} />
                        <li className="description-time" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionTimeEn : languageText.descriptionTime}} />
                        <li className="description-caution" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionCautionEn : languageText.descriptionCaution}} />
                        <li className="description-expiry" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionExpiryEn : languageText.descriptionExpiry}} />
                        <li className="description-soldout" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionSoldOutEn : languageText.descriptionSoldOut}} />
                        <li className="description-responsive" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.descriptionResponsiveEn : languageText.descriptionResponsive}} />
                      </ul>
                      <hr />
                      <p className="small">
                        {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4"  id="home">
              <div id="indexfuture" className="section  qlink-section">
                      <div className="page-header">
                        <h1 className="small">
                          {siteLang == "eng" ? languageText.liveIndexTitleEn : languageText.liveIndexTitle}
                        </h1>
                      </div>
                      <table className="table table-striped table-noHeaderborder" style={{tableLayout: 'fixed'}}>
                        <thead>
                          <tr >
                            <th>{siteLang === "eng" ? languageText.futureTableIndexEn : languageText.futureTableIndex}</th>
                            <th>{siteLang === "eng" ? languageText.futureTableContractEn : languageText.futureTableContract} </th>
                            <th> {siteLang === "eng" ? languageText.futureTableLastEn : languageText.futureTableLast}</th>
                            <th>{siteLang === "eng" ? languageText.futureTableChangeEn : languageText.futureTableChange} </th>
                            <th>{siteLang === "eng" ? languageText.futureTableChangePerEn : languageText.futureTableChangePer}%</th>
                            <th style={{width: '35px'}}/>
                          </tr>
                        </thead>
                        <tbody>
                          {hsidata && <tr className="row_HSI">
                            <td>
                              <Link to="/tools/dwranking/HSI">HSI*</Link>
                            </td>
                            <td className="col_month">{hsidata?.month}</td>
                            <td className="col_last">{new Intl.NumberFormat('en-US').format(hsidata?.last)}</td>
                            <td className={Number(hsidata?.chng) > 0 ? "col_chng val up" : Number(hsidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                              {Number(hsidata?.chng) > 0 ? "+" + hsidata?.chng : hsidata?.chng}
                            </td>
                            <td className={hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hsidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hsidata?.pchng) > 0 ? "col_pchng val up" : Number(hsidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                              {hsidata?.pchng != 0 ? (Number(hsidata?.pchng?.replace("%", "")) > 0 ? '+' + hsidata?.pchng?.replace("%", "") : hsidata?.pchng?.replace("%", "")) : (Number(hsidata?.pchng) > 0 ? '+' + hsidata?.pchng : hsidata?.pchng)}
                            </td>
                            <td rowSpan={3} style={{ verticalAlign: 'middle' }}>
                              <img onClick={refreshdata} className="refresh_index" style={{ cursor: 'pointer', width: '22px' }} src="/img/bullet/refresh.png" alt="refresh" />
                            </td>
                          </tr>}
                          {htidata && <tr className="row_HTI">
                            <td>
                              <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                            </td>
                            <td className="col_month">{htidata?.month}</td>
                            <td className="col_last">{new Intl.NumberFormat('en-US').format(htidata?.last)}</td>
                            <td className={Number(htidata?.chng) > 0 ? "col_chng val up" : Number(htidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                              {Number(htidata?.chng) > 0 ? "+" + htidata?.chng : htidata?.chng}
                            </td>
                            <td className={htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(htidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(htidata?.pchng) > 0 ? "col_pchng val up" : Number(htidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                              {htidata?.pchng != 0 ? (Number(htidata?.pchng?.replace("%", "")) > 0 ? '+' + htidata?.pchng?.replace("%", "") : htidata?.pchng?.replace("%", "")) : (Number(htidata?.pchng) > 0 ? '+' + htidata?.pchng : htidata?.pchng)}
                            </td>
                          </tr>}
                          {hhidata && <tr className="row_HHI">
                            <td>
                              <Link to="/tools/dwranking/HSCEI">HSCEI*</Link>
                            </td>
                            <td className="col_month">{hhidata?.month}</td>
                            <td className="col_last">{new Intl.NumberFormat('en-US').format(hhidata?.last)}</td>
                            <td className={Number(hhidata?.chng) > 0 ? "col_chng val up" : Number(hhidata?.chng) < 0 ? "col_chng val down" : "col_chng val"}>
                              {Number(hhidata?.chng) > 0 ? "+" + hhidata?.chng : hhidata?.chng}
                            </td>
                            <td className={hhidata?.pchng != 0 ? (Number(hhidata?.pchng?.replace("%", "")) > 0 ? "col_pchng val up" : Number(hhidata?.pchng?.replace("%", "")) < 0 ? "col_pchng val down" : "col_pchng val") : (Number(hhidata?.pchng) > 0 ? "col_pchng val up" : Number(hhidata?.pchng) < 0 ? "col_pchng val down" : "col_pchng val")}>
                              {hhidata?.pchng != 0 ? (Number(hhidata?.pchng?.replace("%", "")) > 0 ? '+' + hhidata?.pchng?.replace("%", "") : hhidata?.pchng?.replace("%", "")) : (Number(hhidata?.pchng) > 0 ? '+' + hhidata?.pchng : hhidata?.pchng)}
                            </td>
                          </tr>}
                        </tbody>
                      </table>
                      <div className="priceUpdateInfo">
                        <em>
                          {siteLang == "eng" ?
                            <span>
                              {languageText.futureClickRefreshEn}
                            </span>
                            :
                            <span>
                              {languageText.futureClickRefreshFirst}
                              <img className="refresh_index" style={{ cursor: "pointer", width: "22px" }} src="/img/bullet/refresh.png" onClick={refreshdata} />
                              {languageText.futureClickRefreshLast}
                            </span>
                          }
                          <br />{siteLang == "eng" ? languageText.futureTablePriceEn : languageText.futureTablePrice} <span className="ticktime">{updateDate}</span>{" "}
                          <br />
                          <div className="indicator-item">
                            {/* <span className="icon-time indicator-item-icon" /> */}
                            <span
                              className="ttip"
                              data-tip
                              data-for={"new-warrant-tooltip"}
                            >
                              HSI and HSTECH data provided by DB Power Online Limited [Disclaimer]
                            </span>
                          </div>
                          <ReactTooltip
                            id={"new-warrant-tooltip"}
                            delayHide={100}
                            place="left-start"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p className="tooltip-content">
                                DB Power Online Limited, �HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies�, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.
                                <br />
                                <br />
                                The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.
                                <img
                                  style={{ width: "160px" }}
                                  src="/img/home/dbpower.png"
                                ></img>
                              </p>
                            </div>
                          </ReactTooltip>
                        </em>
                      </div>
              </div>
              <div className="holder-newsbanner">
                    <div id="newsbanner" className="section qlink-section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.marketNewsTitleEn : languageText.marketNewsTitle}</h1>
                      </div>
                      <Slider {...settings} className="show-desk">
                        {dailyHsiBanner && <div className="gallery-item">
                          <a
                            className="homeimg-achor"
                            target="_blank"
                            href={`/marketnews/highlight?cat=hsi&id=${dailyHsiBanner.id}`}
                          >
                            <img
                              src={dailyHsiBanner.img}
                            />
                          </a>
                        </div>}
                        {dailyHighlightBanner && <div className="gallery-item">
                          <a
                            className="homeimg-achor"
                            target="_blank"
                            href={`/marketnews/highlight?cat=todayhighlight&id=${dailyHighlightBanner.id}`}
                          >
                            <img
                              src={dailyHighlightBanner.img}
                            />
                          </a>
                        </div>}
                        {dailySingleBanner && <div className="gallery-item">
                          <a
                            className="homeimg-achor"
                            target="_blank"
                            href={`/marketnews/highlight?cat=singlestock&id=${dailySingleBanner.id}`}
                          >
                            <img
                              src={dailySingleBanner.img}
                            />
                          </a>
                        </div>}
                      </Slider>
                    </div>
              </div>
              <LiveMatrixSideBars />
              <WarrantSearchSideBars />
              <DwTermsSideBars />
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DwRanking;