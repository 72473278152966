export const languageText ={
    marketNewsTitle: "อัพเดทตลาดหุ้น",
    marketNewsTitleEn: "Market news",

    liveIndexTitle: "สัญญาซื้อขายล่วงหน้า",
    liveIndexTitleEn: "Live index futures",
    futureTableIndex: "ตลาด",
    futureTableIndexEn: "Index",
    futureTableContract: "สัญญา",
    futureTableContractEn: "Contract",
    futureTableLast: "ราคา\nล่าสุด",
    futureTableLastEn: "Last",
    futureTableChange: "เปลี่ยน\nแปลง",
    futureTableChangeEn: "Change",
    futureTableChangePer: "เปลี่ยนแปลง ",
    futureTableChangePerEn: "Change",
    futureClickRefreshFirst: "*กดปุ่ม ",
    futureClickRefreshLast: " เพื่ออัพเดท ราคา Live quote",
    futureClickRefreshEn: "* Click refresh button for live quote",
    futureTablePrice: "ณ ",
    futureTablePriceEn: "Price as at ",

    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " DW by Underlying ",
    secondNavigationEn: " DW by Underlying ",

    pageTitle: "DW by Underlying",
    pageTitleEn: "DW by Underlying",
    pageDescription: "มองหา DW28 โดยแบ่งตามหลักทรัพย์อ้างอิงด้วยตารางสรุป ดูง่ายๆ แม้จะดูผ่านโทรศัพท์มือถือ",
    pageDescriptionEn: "See all DW28 available for each underlying in a simple format suitable for mobile.",

    callTableCallDw: "DW ที่เกี่ยวข้อง<br>(มองขึ้น)",
    callTableCallDwEn: "Call DW<br>(Bullish view)",
    callTableBid: "ราคาเสนอซื้อ<br>(บาท)",
    callTableBidEn: "Bid<br>(THB)",
    callTableGearing: "Eff.<br>gearing(x)",
    callTableGearingEn: "Eff.<br>gearing(x)",
    callTableSensitivity: "Sensitivity",
    callTableSensitivityEn: "Sensitivity",
    callTableSensitivityPoint: "Sensitivity (จุดดัชนี)",
    callTableSensitivityPointEn: "Sensitivity by Index Point",

    putTableCallDw: "Put DW<br>(มองลง)",
    putTableCallDwEn: "Put DW<br>(Bearish view)",

    effectiveGearingTooltip: '<b>Effective gearing:</b>ใช้บ่งชี้โอกาสในการรับผลตอบแทนจากการลงทุนใน DW ตัวอย่างเช่น Effective Gearing เท่ากับ 5 เท่า หมายความว่า ถ้าราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปประมาณ 5% <a target="_blank" href="/eduhtml/effectivegearing">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม',
    effectiveGearingTooltipEn: '<b>Effective gearing:</b>Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your DW price. <a target="_blank" href="/eduhtml/effectivegearing">click here</a> to learn more about effective gearing.',
    sensitivityTooltip: '<b>Sensitivity:</b>แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา',
    sensitivityTooltipEn: '<b>Sensitivity:</b>Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks.',
    sensitivityIndexTooltip: '<b>Sensitivity (จุดดัชนี):</b>แสดงถึงจำนวนจุดดัชนีของดัชนีอ้างอิง เมื่อราคา DW เปลี่ยนแปลง 1 ช่วงราคา เช่น ค่า Sensitivity (จุดดัชนี) = 5 หมายถึง ราคา DW จะเปลี่ยนแปลง 1 ช่วงราคา เมื่อดัชนีอ้างอิงเปลี่ยนแปลงประมาณ 5 จุดดัชนี',
    sensitivityIndexTooltipEn: '<b>Sensitivity by Index Point:</b>indicates the number of underlying index point for a 1 tick move in the DW. Eg. Sensitivity by Index Point = 5 means that DW will move 1 tick when underlying index moves around 5 index points.',

    descriptionHotDw: ": DW ที่เร็ว แรง เติมไว และไม่หน่วง สามารถทำให้นักลงทุนซื้อ/ขาย DW ได้ภายในเสี้ยววินาที",
    descriptionHotDwEn: ": DW with high speed refilled. It allows investors to buy / sell millions of DW less than a second.",
    descriptionTime: ': เมื่อ DW ตัวใดมีการแสดงเครื่องหมาย Low Time Decay นั้นหมายถึง DW รุ่นนั้นมีอายุยาวและค่า Time decay ต่อวันต่ำ (โดยคิดเป็นจำนวน 4 วันหรือมากกว่า ถึงจะปรับตัวลดลง 0.01 บาท /หรือ จำนวน 2 วันหรือมากกว่า ในกรณีที่หลักทรัพย์อ้างอิงเป็นดัชนี) ทั้งนี้ DW รุ่นดังกล่าวเหมาะสำหรับนักลงทุนที่มีกลยุทธ์ที่ต้องการถือ DW เป็นระยะยาว',
    descriptionTimeEn: ': Where the DW code indicates low time decay the warrant has a longer time to maturity and lower daily time decay (around 4 days or more to decay 0.01 bt. or 2 days or more in case underlying is index) which makes it suitable for where your trading strategy requires a longer holding period.',
    descriptionCaution: ': เป็น DW ที่มีความเสี่ยงสูง เช่น มีมูลค่าและค่า Sensitivity ต่ำ เป็นต้น เพื่อเป็นการลดความเสี่ยง ทาง Market Maker อาจจะพิจารณาขยับราคา Offer แต่ยังคงราคา Bid ไว้ที่ระดับเดิม นักลงทุนสามารถขายคืนได้ตามปกติ คลิก <a href="https://www.thaidw.com/education/individualtip?id=97">อ่านข้อมูล</a>',
    descriptionCautionEn: ': It indicates DWs with high risk ie Low price and Sensitivity. In order to reduce a risk, Market Maker may consider to increase only the offer price but keep Bid at the same level. Therefore investors who previously bought into the DW are not disadvantaged. Find out more <a href="https://www.thaidw.com/education/individualtip?id=97">click</a>.',
    descriptionExpiry: ': หมายถึง DW ที่มีอายุคงเหลือน้อยกว่า 1 เดือน ซึ่งมีแนวโน้มว่า DW รุ่นนั้นจะมีความเสี่ยงสูง นักลงทุนควรเทรดด้วยความระมัดระวัง',
    descriptionExpiryEn: ': Indicates DWs which are less than 1 month to expiry. These DW tend to be very high risk, please trade with caution.',
    descriptionSoldOut: ': หากชื่อย่อ DW แสดงเครื่องหมาย Inventory sold-out กำกับ หมายถึง ผู้ออกเสนอขาย DW เป็นจำนวนมากกว่า 95% ของจำนวนที่ขอจดทะเบียนกับตลาดหลักทรัพย์ฯ ซึ่งหมายความว่า ผู้ดูแลสภาพคล่องอาจจะมีการวางราคาเสนอขาย (Offer) ห่างจากราคาเสนอซื้อ (Bid) มากกว่า 1 ช่วงราคา หรืออาจไม่สามารถวางราคาเสนอขายได้ ผู้ที่ถือ DW อยู่แล้วจะไม่เสียประโยชน์ และยังสามารถขาย DW คืนให้ผู้ดูแลสภาพคล่องที่ราคาเสนอซื้อที่เหมาะสม (fair bid price) ได้ อย่างไรก็ตาม นักลงทุนต้องระมัดระวังอย่างมากในการซื้อ DW รุ่นนี้เนื่องจากราคา DW อาจเพิ่มสูงขึ้นจากการเก็งกำไรได้',
    descriptionSoldOutEn: ': Where the DW code has inventory sold-out sign it means that more than 95% of the DW inventory has been sold. This means that the market maker may no longer provide an offer price or have widened their offer spread. Existing holders of the DW are not disadvantaged and they can still sell to the market maker at a fair bid price. However please be very cautious if you are buying this DW. The price may be inflated by speculators.',
    descriptionResponsive: ': หมายถึง DW รุ่นที่เคลื่อนไหวตามราคาหลักทรัพย์อ้างอิงได้ดี <a target="_blank" href="https://www.thaidw.com/education/individualtip?id=135&amp;type=set">คลิกที่นี่</a> เพื่ออ่านข้อมูลเพิ่มเติม',
    descriptionResponsiveEn: ': Indicates DWs that are highly responsive to the movement of underlying price <a target="_blank" href="https://www.thaidw.com/education/individualtip?id=135&amp;type=set">Click here</a> for more information',

    definationSensitivity: "Sensitivity: ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ทำให้ราคาของ DW ปรับขึ้นประมาณ 1.2 ช่วงราคา ทั้งนี้ค่า Sensitivity จะเปลี่ยนแปลงเมื่อราคาหลักทรัพย์อ้างอิงเปลี่ยนแปลง",
    definationSensitivityEn: "Sensitivity: Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks. Sensitivity will change as the share price changes.",
    definationEffectiveGearing: "Effective Gearing: ใช้บ่งชี้โอกาสในการรับผลตอบแทนจากการลงทุนใน DW ตัวอย่างเช่น Effective Gearing เท่ากับ 5 เท่า หมายความว่า ถ้าราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปประมาณ 5%",
    definationEffectiveGearingEn: "Effective Gearing: Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your DW price.",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}